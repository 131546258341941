import './Cv.css';

function Cv() {
  return (
    <div className='container'>
      <div className='name_wrapper'>
        <div className='contact_with_name_wrapper'>
          <h1>
            Paweł <span>Tomczak</span>
          </h1>
          <div className='contact_wrapper'>
            <div className='contact_location'>
              <i className='fa-solid fa-location-dot'></i> Warsaw, Poland -
              Remote
            </div>
            <div className='contact_mobile'>
              <a href='tel:+48664064851'>
                <i className='fa-solid fa-phone'></i> +48 664 064 851
              </a>
            </div>
            <div className='contact_email'>
              <a href='mailto:contact@paweltomczak.dev'>
                <i className='fa-solid fa-envelope'></i>{' '}
                contact@paweltomczak.dev
              </a>
            </div>
            <div className='contact_github'>
              <a
                href='https://github.com/paweltomczak'
                target='_blank'
                rel='noreferrer'
              >
                <i className='fa-brands fa-github'></i> paweltomczak
              </a>
            </div>
            <div className='contact_linkedin'>
              <a
                href='https://www.linkedin.com/in/paweltomczak/'
                target='_blank'
                rel='noreferrer'
              >
                <i className='fa-brands fa-linkedin'></i> paweltomczak
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='section_wrapper summary'>
        <div className='section_heading'>
          <i className='fa-solid fa-code'></i>
          <h2>Summary</h2>
        </div>
        <div className='section'>
          <p className='section_job_about'>
            React Developer with over 4 years of proficiency with Front End
            Development experience of 8 years. Proven track record of leading
            development teams and delivering high-quality web solutions for
            global brands, enhancing user experience and driving successful
            project launches.
          </p>
        </div>
      </div>
      <div className='sections_wrapper'>
        <div className='section_wrapper-left'>
          <div className='section_wrapper experience'>
            <div className='section_heading'>
              <i className='fa-solid fa-clipboard-list'></i>
              <h2>Experience</h2>
            </div>
            <div className='section_job'>
              <p className='section_title'>Deloitte Digital</p>
              <p className='section_position'>
                Front End Developer
                <br />
                Studio Professional, Studio Senior, Studio Lead
              </p>
              <ul>
                <li>
                  Played a key role in the successful launch of a global Store
                  Locator microservice using React, MobX, TypeScript, GraphQL,
                  and Jest.
                </li>
                <li>
                  Led a team of 3 developers in the e-commerce development and
                  launch of 5 locales across 5 brands, contributing to the
                  digital presence of a International Beauty Company with net
                  sales of $15.91bn in FY2023.
                </li>
                <li>
                  Spearheaded the redesign of the checkout process for a major
                  brand in the EMEA region, directly enhancing the online
                  purchasing experience.
                </li>
                <li>
                  Collaborated extensively with cross-functional teams,
                  including QA and developers across countries, to ensure
                  seamless deployment and functionality of new sites (10 brands
                  for 10 locales).
                </li>
                <li>
                  Managed and reviewed pull requests in Bitbucket and GitHub,
                  fostering code quality and team collaboration.
                </li>
                <li>
                  Utilized Jira for issue tracking and Confluence for
                  documentation, improving project visibility and documentation
                  accuracy.
                </li>
                <li>
                  Streamlined Agile methodologies, including daily stand-ups,
                  sprint planning, and retrospectives, to streamline project
                  delivery and ensure alignment with project goals.
                </li>
                <li>
                  Implemented a comprehensive knowledge base, reducing
                  onboarding time by 25%.
                </li>
              </ul>
              <p className='section_date'>2018-2024</p>
            </div>
          </div>
          <div className='section_wrapper experience'>
            <div className='section_heading'>
              <i class='fas fa-laptop-code'></i>
              <h2>Personal Project</h2>
            </div>
            <div className='section_job'>
              <p className='section_title'>
                <a
                  href='https://www.verbitus.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  Verbitus.com
                </a>
              </p>
              <p className='section_position'>Full-Stack / React Developer</p>
              <p>
                Developed a marketing platform using React, TypeScript, Next.js,
                and Tailwind CSS. Designed and implemented a dynamic admin
                dashboard for content management, integrated PostgreSQL for
                secure data storage, and utilized NextAuth for user
                authentication. Built features such as rich text editing, file
                uploads (Firebase), and dynamic page displays, ensuring a
                scalable and visually appealing web application.
              </p>
              <p className='section_date'>2024</p>
            </div>
          </div>
        </div>
        <div className='section_wrapper-right'>
          <div className='section_wrapper section_skill'>
            <div className='section_heading'>
              <i className='fa-solid fa-star'></i>
              <h2>Skills</h2>
            </div>
            <p className='section_title'>Languages & Frameworks & Database</p>
            <ul className='section_languages'>
              <li>React</li>
              <li>JavaScript</li>
              <li>TypeScript</li>
              <li>Next.js</li>
              <li>HTML/CSS/SCSS</li>
              <li>Tailwind CSS</li>
              <li>MobX</li>
              <li>GraphQL</li>
              <li>Jest</li>
              <li>PostgreSQL</li>
              <li>Firebase</li>
            </ul>
            <div className='seciont_other'>
              <div>
                <p className='section_title'>Tools & Platforms</p>
                <ul>
                  <li>GitHub</li>
                  <li>Bitbucket</li>
                  <li>Jira</li>
                  <li>Confluence</li>
                  <li>Figma</li>
                  <li>Jenkins</li>
                </ul>
              </div>
              <div>
                <p className='section_title'>Methodologies</p>
                <ul>
                  <li>Agile - Scrum</li>
                  <li>Code Review</li>
                  <li>Debugging</li>
                  <li>User Story Creation</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='section_wrapper'>
            <div className='section_heading'>
              <i className='fa-solid fa-graduation-cap'></i>
              <h2>Education</h2>
            </div>
            <p className='section_title'>Analysis and Management in Business</p>
            <p className='section_position'>
              Lodz University - Bachelor degree
            </p>
            <p>
              Subject: "
              <span>
                Web analytics, as an opportunity for businesses
                <br />
                to gain competitive advantage
              </span>
              "
            </p>
            <p className='section_date'>2012-2016</p>
          </div>
          <div className='section_wrapper'>
            <div className='section_heading'>
              <i className='fa-solid fa-certificate'></i>
              <h2>Certificates</h2>
            </div>
            <p className='section_title'>Udacity</p>
            <p>
              <a href='https://confirm.udacity.com/HTGJ6DHA'>
                React Nanodegree Program
              </a>
            </p>
            <p className='section_date'>2022</p>
            <p className='section_title'>sages</p>
            <p>Building an application using ReactJS</p>
            <p className='section_date'>2021</p>
          </div>

          <div className='section_wrapper'>
            <div className='section_heading'>
              <i className='fa-solid fa-microphone'></i>
              <h2>Languages</h2>
            </div>
            <p>Polish - Native</p>
            <p>English - Fluent</p>
          </div>
        </div>
      </div>
      <div className='full_width_wrapper'>
        I agree to the processing of personal data provided in this document for
        realising the recruitment process pursuant to the Personal Data
        Protection Act of 10 May 2018 (Journal of Laws 2018, item 1000) and in
        agreement with Regulation (EU) 2016/679 of the European Parliament and
        of the Council of 27 April 2016 on the protection of natural persons
        with regard to the processing of personal data and on the free movement
        of such data, and repealing Directive 95/46/EC (General Data Protection
        Regulation).
      </div>
    </div>
  );
}

export default Cv;
